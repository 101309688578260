import React from 'react';

export const COMPONENT_MAP = {
  tileActionButton: React.lazy(() => import('../components/TileActionButton')),
  gridLayout: React.lazy(() => import('../components/GridLayout')),
  tab: React.lazy(() => import('../components/Tab')),
  searchBar: React.lazy(() => import('../components/SearchBar')),
  actions: React.lazy(() => import('../components/Actions')),
  section: React.lazy(() => import('../components/Section')),
  card: React.lazy(() => import('../components/Card')),
  traceFragment: React.lazy(() => import('../components/trackAndTrace/Fragment')),
  breadCrumb: React.lazy(() => import('../components/BreadCrumb')),
  listTable: React.lazy(() => import('../components/ListTable')),
  bulkUpload: React.lazy(() => import('../components/bulkUpload/BulkUpload')),
  grid: React.lazy(() => import('../components/Grid')),
  multiAsset: React.lazy(() => import('../components/GUMultiAssetRenderer')),
  checklistPages: React.lazy(() => import('../components/checklist/ChecklistPages')),
  customFieldSection: React.lazy(() => import('../components/checklist/CustomFieldSection')),
  executeChecklist: React.lazy(() => import('../components/checklist/ExecuteChecklist')),
  kanbanBoard: React.lazy(() => import('../components/tickets/TicketsBoard')),
  ticketTable: React.lazy(() => import('../components/tickets/TicketTable')),
  notificationCard: React.lazy(() => import('../components/notifications/NotificationCard')),
  processCanvas: React.lazy(() => import('../components/processCanvas/ProcessCanvas')),
  popupOpener: React.lazy(() => import('../components/PopupOpener')),
  ticketsCards: React.lazy(() => import('../components/TicketCards')),
  inputMaterialsWorkOrder: React.lazy(() => import('../components/InputMaterialsWorkOrder')),
  workOrderLinkedTicket: React.lazy(() => import('../components/WorkOrderLinkedTickets')),
  trackAndTraceTickets: React.lazy(() => import('../components/trackAndTrace/Tickets')),
  trackAndTraceAttachments: React.lazy(() => import('../components/trackAndTrace/Attachments')),
  profile: React.lazy(() => import('../components/profile/Profile')),
  customActionConsole: React.lazy(
    () => import('../components/operatorDashboard/CustomActionConsole'),
  ),
  actionHeader: React.lazy(() => import('../components/ActionHeader')),
  pauseActionHeader: React.lazy(() => import('../components/leadingWorkOrder/PauseActionHeader')),
  break: React.lazy(() => import('../components/leadingWorkOrder/Break')),
  additionalActions: React.lazy(() => import('../components/leadingWorkOrder/AdditionalActions')),
  anchor: React.lazy(() => import('../components/leadingWorkOrder/Anchor')),
  displayLabel: React.lazy(() => import('../components/DisplayLabel')),
  ticketDetail: React.lazy(() => import('../components/tickets/TicketDetails')),
  ticketHeader: React.lazy(() => import('../components/tickets/TicketHeader')),
  ticketInfo: React.lazy(() => import('../components/tickets/TicketInfo')),
  ticketAttachment: React.lazy(() => import('../components/tickets/TicketAttachment')),
  ticketComments: React.lazy(() => import('../components/tickets/TicketComments')),
  actionSection: React.lazy(() => import('../components/ActionSection')),
  timelineList: React.lazy(() => import('../components/TimeLine')),
  customSupervisorScreen: React.lazy(
    () => import('../components/supervisorDashboard/CustomSupervisorScreen'),
  ),
  allocatedJobListScreen: React.lazy(() => import('../components/leadingWorkOrder/AllocatedJob')),
  currentJob: React.lazy(() => import('../components/leadingWorkOrder/CurrentJob')),
  customPMSSheet: React.lazy(() => import('../components/projects/CustomPMSSheet')),
  contextVarSection: React.lazy(() => import('../components/tickets/ContextVarSection')),
  customProjectTab: React.lazy(() => import('../components/projects/CustomProjectTab')),
  aisTracker: React.lazy(() => import('../components/ais/AisTracker')),
  multipleEntry: React.lazy(() => import('../components/pendingItems/MultipleEntry')),
  multipleEntrySsil: React.lazy(() => import('../components/pendingItems/PendingItemSsil')),
  actionPanel: React.lazy(() => import('../components/ActionPanel')),
  policyAttacher: React.lazy(() => import('../components/PolicyAttacher')),
  projectDetails: React.lazy(() => import('../components/projects/ProjectDetails')),
  phoneInputField: React.lazy(() => import('../components/PhoneInputField')),
  cheklistSelection: React.lazy(() => import('../components/checklist/CheklistSelection')),
  treeStructure: React.lazy(() => import('../components/TreeStructure')),
  trackAndTraceTreeStructure: React.lazy(
    () => import('../components/trackAndTrace/LeadingWorkOrder'),
  ),
  fragment: React.lazy(() => import('../components/Fragment')),
  aggregatedProductionPlan: React.lazy(
    () => import('../components/productionPlan/aggregatedProductionPlan'),
  ),
  groupingTable: React.lazy(() => import('../components/GroupingTable')),
  ticketBulkUpload: React.lazy(() => import('../components/tickets/TicketBulkUpload')),
  workOrderBreakup: React.lazy(() => import('../components/productionPlan/WorkOrderBreakup')),
  realTimeDashboard: React.lazy(() => import('../components/realTimeDashboard/RealTimeDashboard')),
  jsonEditorSection: React.lazy(() => import('../components/JsonEditorSection')),
  dailySchedule: React.lazy(() => import('../components/productionPlan/DailySchedule')),
  tabLayoutViewer: React.lazy(() => import('../components/TabLayoutViewer')),
  notificationSettings: React.lazy(() => import('../components/NotificationSettings')),
  resourceAllocation: React.lazy(
    () => import('../components/resourceAllocation/ReasourceAllocation'),
  ),
  bulkResourceAllocation: React.lazy(
    () => import('../components/resourceAllocation/BulkAllocation'),
  ),
  gembaMachineTabs: React.lazy(() => import('../components/gembaDashboard/MachineTabs')),

  // Chart components
  reportFilter: React.lazy(() => import('../components/charts/ReportFilter')),
  lineChart: React.lazy(() => import('../components/charts/LineChart')),
  barChart: React.lazy(() => import('../components/charts/BarChart')),
  barChartWithLine: React.lazy(() => import('../components/charts/BarChartWithLine')),
  pieChart: React.lazy(() => import('../components/charts/PieChart')),
  ganttChart: React.lazy(() => import('../components/charts/GanttChart')),
  statsCard: React.lazy(() => import('../components/charts/StatsCard')),
  traceList: React.lazy(() => import('../components/trackAndTrace/TraceList')),
  calendarSchedule: React.lazy(() => import('../components/productionPlan/CalendarSchedule')),
  cloneCanvas: React.lazy(() => import('../components/processCanvas/CloneCanvas')),
  inputBomMaterial: React.lazy(() => import('../components/tickets/InputBomMaterial')),
  andOnScreen: React.lazy(() => import('../components/andOn/AndOnScreen')),
  colorPicker: React.lazy(() => import('../components/ColorPicker')),
  alertReports: React.lazy(() => import('../components/charts/AlertReports')),
  materialShortage: React.lazy(() => import('../components/operatorDashboard/MaterialShortageTicket')),
  machineDashboard: React.lazy(() => import('../components/machineDashboard/MachineDashboard')),
  equipmentTracker: React.lazy(() => import('../components/machineDashboard/EquipmentTracker')),
};
