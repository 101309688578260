import { getSpanClass, getLayoutColClass } from '../utils/layoutHelpers';
import { Grid, Input, Label } from './twEssential';

const SearchBar = (props) => {
  const { data, onSearchInputChangeHandler, isFullWidth, placeholder, searchValue, alertReports } =
    props;
  return (
    <Grid
      column={getLayoutColClass(data)}
      span={getSpanClass(data?.span)}
      className={`${isFullWidth ? 'py-2' : 'p-2'}`}
    >
      <form className={`${isFullWidth ? '' : 'flex items-center ml-auto'}`}>
        {!alertReports ? (
          <Label for="simple-search" className="sr-only">
            Search
          </Label>
        ) : (
          ''
        )}

        <div className="relative">
          <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
            <svg
              className="w-5 h-5 text-gray-500 dark:text-gray-400"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                clipRule="evenodd"
              ></path>
            </svg>
          </div>
          <Input
            type="text"
            id="simple-search"
            placeholder={placeholder ? placeholder : 'Search'}
            required
            className="px-10"
            value={searchValue}
            onChange={onSearchInputChangeHandler}
          />
        </div>
      </form>
    </Grid>
  );
};

export default SearchBar;
