import { configureStore } from '@reduxjs/toolkit';
import screenReducer from './features/screen/screenSlice';
import notificationReducer from './features/screen/notificationSlice';

export const store = configureStore({
  reducer: {
    screen: screenReducer,
    notification: notificationReducer,
  },
});
